import React from 'react'
import { FaServicestack} from "react-icons/fa6";
import { FaFileImport} from "react-icons/fa6";
import { FaPlaceOfWorship} from "react-icons/fa6";
import { FaTrainp} from "react-icons/fa6";
import { FaTruckFront} from "react-icons/fa6";
import { FaShip} from "react-icons/fa6";
import { FaBuildingUser} from "react-icons/fa6";









const Services = () => {
  return (
    <div className="container-fluid">
        <h1 className='text-center' >Services</h1>
        <div className="container-fluid">
        <div class="image-container">
<img className='img-fluid rounded' src="images/portfolio/35.jpg" alt="" width='100%'/>
</div>
</div>

<div className="container-fluid p-5" id='Services'>
<h2 className='text-center' >Our Servicses</h2>
<div className="row p-5">
<div class="col-lg-4 grow-on-hover">
     <h5><FaServicestack/></h5>
    <h4>Custom Clearence Services</h4>
    <p>We Provide All Ports Custom Clearance Services in Pakistan.</p>
  </div>

  <div class="col-lg-4 grow-on-hover">
    <h5><FaFileImport/></h5>
  <h4>Import Permit Issuance</h4>
    <p>You Can Get All Types Of Import Permits From Us.</p>
  </div>
  <div class="col-lg-4 grow-on-hover">
  <h5><FaPlaceOfWorship/></h5>
  <h4>Shipping Companies Related Work</h4>
    <p>Minhas Brother Provides All Shipping Companies Related Services In Pakistan.</p>
  </div>
  <div class="col-lg-4 grow-on-hover">
  <h5><FaTruckFront/></h5>
  <h4>Supply Chain</h4>
    <p>You Can Get All Types Of Vehicle From Us, Small Truck to Big Trucks.</p>
  </div>
  <div class="col-lg-4 grow-on-hover">
  <h5><FaShip/></h5>
  <h4>Ports And Customs</h4>
    <p>We Have a Good and Long Terms Relationships With All Ports & Customs Authorities.</p>
  </div>


  <div class="col-lg-4 grow-on-hover">
  <h5><FaBuildingUser/></h5>
  <h4>We Gives Complete Imports/Exports Services</h4>
    <p>You Can Reach Us For All of Your Imports and Exports Shipments.</p>
  </div>
</div>


</div>















    </div>
  )
}

export default Services