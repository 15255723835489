import React from 'react';
import './Home.css';
import { FcAndroidOs } from "react-icons/fc";
import { FcBullish } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa6";


const Home = () => {
  return (
    <div>

        <div className="container-fluid" id='Home'>
            <img src="images/portfolio/30.jpg" alt="" width='100%'/>
        </div>

        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-lg-6">
            <div class="image-container">
            <img className='img-fluid rounded' src="images/portfolio/34.jpg" alt="" width='100%'/>
            </div>
            </div>
            <div className="col-lg-6">
              <h1>MINHAS BROTHERS</h1>
              <p>Minhas Brothers is an authorized clearing forwarding agent in Pakistan and we seek to provide the best kind of services as a custom clearance company. Every business person knows that there are several processes which go into the transportation of a shipment. Every country has a set of rules and regulations and even requirements which have to be followed in order for the shipment to be transported successfully. Custom Clearance is hefty work which involves preparation and submission of documentations which are compulsory as they are meant to facilitate the imports or the exports in a country. The person who does all the preparation and submission of documents, and also represents the client during customs assessment, examination and during the time that the cargo is being delivered from the customs is known as the Custom Clearance Agents or Clearing Forwarding Agents.</p>
            </div>
          </div>
        </div>


        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-lg-6">
              <h1>CUSTOM CLEARANING AGENT</h1>
              <p>Minhas Brothers Group proudly hold position as one of the leading Importer, Exporter & Customs Clearing Agent company in Karachi,PAKISTAN. Minhas Brothers has developed trade business in a variety of commodities and services, such as Rice,Grain Chickpeas, Fisheries, Meat and many more products MB success lies on the uniqueness of relations with suppliers, government departments, loyal customers and dedicated workforce. Minhas Brothers provides customs solution in Karachi,Pakistan and connects worldwide with professional service and support.</p>
            </div>
            <div className="col-lg-6">
            <div class="image-container">
            <img className='img-fluid rounded' src="images/portfolio/24.jpg" alt="" width='100%'/>
            </div>
            </div>
          </div>
        </div>


{/* background image 1 start  */}
        <div className="container-fluid bg d-flex align-items-center justify-content-center">
<div className="container">
<h1 className='text-center'>Hire us</h1>
</div>
</div>
{/* background image 1 end */}


{/* background image 2 start  */}
<div className="container-fluid d-flex align-items-center justify-content-center" id='bg2'>
<div className="container">     
<h1>For Your Shipment</h1>
</div>     
</div>
{/* background image 2 end */}



        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-lg-6">
            <div class="image-container">
            <img className='img-fluid rounded' src="images/portfolio/23.jpg" alt="" width='100%'/>
            </div>
            </div>
            <div className="col-lg-6">
              <h1>WHY CHOOSE US?</h1>
              <p>We deal in all modes of transportation for delivering cargo from air to water, land We can provide you cost-effective shipping solutions according to your budget We can even arrange storage for your cargo and act as your warehousing service provider We can even negotiate with the shipping line to get you the freight rates you need We can process all kinds of shipping documents such as Certificate of Origin, Bill of Lading etc. We can arrange for your cargo to be transmitted from your premises to the port We have been working in the industry from almost twenty years We have an established network all over the country to process your procedures We are registered in several renowned organizations such as PIFFA, FIATA, IATA and others..</p>
            </div>
          </div>
        </div>

        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-lg-6">
            <div class="image-container">
            <img className='img-fluid rounded' src="images/portfolio/38.jpg" alt="" width='100%'/>
            </div>
            </div>
            <div className="col-lg-6">
              <h1>SUPPLY CHAIN</h1>
              <p>MB also provides additional trade services to suppliers and customers for supply chain operational synergies that compliment and reduce the overall cost of operations to the customer. We have well established strategic business partners operating in the all over Pakistan</p>
            </div>
          </div>
        </div>


        {/* Our Products */}

        <div className="container-fluid p-5">
          <h1 className='text-center p-5'>Our Products</h1>
          <div className="row">
            <div className="col-lg-4">
            <div class="image-container">
              <img className='img-fluid rounded' src="images/portfolio/5.jpg" alt="" width='100%'/>
              <h4 className='text-center'>Corn</h4>
              </div>
              </div>

            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="images/portfolio/20.jpg" alt="" width='100%' id='tt'/>
              <h4 className='text-center'>Rice</h4>
            </div>
            </div>
            
            <div className="col-lg-4" id='card'>
            <div class="image-container">
            <img className='img-fluid rounded' src="images/portfolio/21.jpg" alt="" width='100%'/>
              <h4 className='text-center'>Chick Peas</h4>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="images/portfolio/8.jpg" alt="" width='100%'/>
              <h4 className='text-center'>Pulses</h4>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="images/portfolio/3.jpg" alt="" width='100%'/>
              <h4 className='text-center'>Mix Pulses</h4>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="images/portfolio/4.jpg" alt="" width='100%'/>
              <h4 className='text-center'>Black Rice</h4>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="images/portfolio/1.jpg" alt="" width='100%'/>
              <h4 className='text-center'>Wheat</h4>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="images/portfolio/6.jpg" alt="" width='100%'/>
              <h4 className='text-center'>Flour</h4>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="image-container">
            <img className='img-fluid rounded' src="images/portfolio/7.jpg" alt="" width='100%'/>
              <h4 className='text-center'>Green Pulses</h4>
            </div>
            </div>
          </div>
        </div>















    </div>
  )
}

export default Home