import React from 'react'

import './Footer.css';
import { FaFacebook} from "react-icons/fa6";
import { FaInstagram} from "react-icons/fa6";
import { FaSquareTwitter} from "react-icons/fa6";
import { FaYoutube} from "react-icons/fa6";





const Footer = () => {
  return (
    <div className="container-fluid p-5" id='Footer'>
<div className="row p-5">
    <div className="col-lg-4">
        <h3>Connect With Us</h3>
       <a href=""> <FaFacebook/></a><br/>
       <a href=""> <FaInstagram/></a><br/>
       <a href=""> <FaSquareTwitter/></a><br/>
       <a href=""> <FaYoutube/></a>
    </div>
   
    <div className="col-lg-4">
        <h3>Our Services</h3>
        <p>Import & Related Matters</p>
        <p>Export & Related Matters</p>
        <p>Supply Chain</p>
    </div>
    <div className="col-lg-4">
        <h3>Contact Us</h3>
        <p>Office No, 106 1st Floor Abdullah Squire New Chali Saddar Karachi Pakistan.</p>
        <p>Mobile Number:0092-300-2317180</p>
        <p>Land Line NO,:0092-21-32427180</p>
        <p>Email:Ansar@minhasbrother.com</p>
    </div>
    </div>
    </div>
  )
}

export default Footer