import React from 'react'
import './Contact.css';



const Contact = () => {
  return (
    <div className="container-fluid">
<h1 className='text-center'>Contact Us</h1>
<div className="container-fluid">
<div class="image-container">
<img className='img-fluid rounded' src="images/portfolio/36.jpg" alt="" width='100%'/>
</div>
</div>



<div className="container-fluid p-5">

  <div className="row">
    <div className="col-lg-6">
      <h3>Location</h3>
      <p>Office No, 106 1st Floor Abdullah Squire New Chali Saddar Karachi Pakistan.</p>
      <h3>Email:</h3>
      <p>Ansar@minhasbrother.com</p>
      <h3>Call:</h3>
      <p>0092-300-2317180</p>
    </div>

    <div className="col-lg-6">
      <form action="" id='form'>
        <h1>Send Message To Us</h1>
        <input type="text" name="Your Name" placeholder='Your Name' id=""/><br></br><br></br>
        <input type="text" name="Phone Number" placeholder='Phone Number' id=""/><br></br><br></br>
        <input type="text" name="email" placeholder='Email Address' id=""/><br></br><br></br>
        <textarea name="Message" id="" cols="50" rows="10" placeholder='Message'></textarea><br></br>
        <button className='btn'>Submit</button>
      </form>






    </div>
  </div>
</div>















        
    </div>
  )
}

export default Contact