import React from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Header.css';




const Header = () => {
  return (
    <>
   <div className="header">
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand to="#home">Minhas Brothers</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"> 
           <Link to="/Home">Home</Link>
            <Link to="/About">About Us</Link>
            <Link to="/Services">Services</Link>
            <Link to="/Contact">Contact</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
    
    </div>
  
    </>
  )
}

export default Header