import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Components/Home';
import Footer from './Components/Footer';
import About from './Components/About';
import Services from './Components/Services';
import Contact from './Components/Contact';
import { BrowserRouter,
  Route,
  Routes,
  route
} from "react-router-dom";





function App() {
  return (
    <>
  <Header/>
    <Routes>

    <Route index element={<Home />}/>
      <Route path='Home' element={<Home />}/>
      <Route path='About' element={<About />}/>
      <Route path='Services' element={<Services />}/>
      <Route path='Contact' element={<Contact />}/>
      
    </Routes> 
    <Footer/>

  
   
    </>
  );
}

export default App;
