import React from 'react'
import './About.css';



const About = () => {
  return (
   
    <div className="container-fluid">
        <h1 className='text-center'>About Us</h1>
<div className="conatiner-fluid">
<div class="image-container">
<img className='img-fluid rounded' src="images/portfolio/32.jpg" alt="" width='100%'/>
</div>
</div>

<div className="container-fluid p-5">
    <div className="row">
        <div className="col-lg-6">
        <h1>MINHAS BROTHERS</h1>
    <p>Minhas Brothers Group proudly hold position as one of the leading Importer, Exporter & Customs Clearing Agent company in Karachi,PAKISTAN. Minhas Brothers has developed trade business in a variety of commodities and services, such as Rice,Grain Chickpeas, Fisheries, Meat and many more products MB success lies on the uniqueness of relations with suppliers, government departments, loyal customers and dedicated workforce. Minhas Brothers provides customs solution in Karachi,Pakistan and connects worldwide with professional service and support..</p>
        </div>
        <div className="col-lg-6">
        <div class="image-container">
        <img className='img-fluid rounded' src="images/portfolio/26.jpg" alt="" width='100%'/>
        </div>
        </div>
    </div>

    <div className="container-fluid p-5">
<div className="row">
<div className="col-lg-6">
<div class="image-container">
<img className='img-fluid rounded' src="images/portfolio/23.jpg" alt="" width='100%'/>
</div>
</div>
    
    <div className="col-lg-6">
        <h1>MINHAS BROTHERS</h1>
        <p>Minhas Brothers is an authorized clearing forwarding agent in Pakistan and we seek to provide the best kind of services as a custom clearance company. Every business person knows that there are several processes which go into the transportation of a shipment. Every country has a set of rules and regulations and even requirements which have to be followed in order for the shipment to be transported successfully. Custom Clearance is hefty work which involves preparation and submission of documentations which are compulsory as they are meant to facilitate the imports or the exports in a country. The person who does all the preparation and submission of documents, and also represents the client during customs assessment, examination and during the time that the cargo is being delivered from the customs is known as the Custom Clearance Agents or Clearing Forwarding Agents.</p>
    </div> 
</div>
    </div>
</div>

    </div>
  )
}

export default About